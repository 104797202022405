<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required,numeric,minLength } from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Vue from "vue";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
/**
 * Orders Component
 */
export default {
  components: {
    DatePicker,
    Layout,
    Multiselect,
    PageHeader
  },
  data() {
    return {
      title: "COUPONS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "COUPONS",
          active: true
        }
      ],
      checkbox:[],
      isCheckAll:false,
      couponData: [],
      userData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: "" },
        { key: "name", sortable: true, label: "COUPON NAME" , thStyle: { color: "black", "font-size":"16px"} },
        { key: "code", sortable: true, label: "COUPON CODE", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "couponUsage", sortable: true, label: "COUPON USAGE", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "worth", sortable: true, label: "WORTH AMOUNT", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "validFrom", sortable: true, label: "VALID", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "firstName", sortable: true, label: "USER", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "minPurchase", sortable: true, label: "MINIMUM PURCHASE", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "status", sortable: true, label: "STATUS", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action" , thStyle: { color: "black", "font-size":"16px"}, label: "ACTION" }
      ],
      coupon: {
        name: "",
        code: "",
        from_date: "",
        from_time: "",
        to_date: "",
        to_time: "",
        worth: "",
        minPurchase: "",
        userId: "",
        type: "",
        usage: "",
        status: "1"
      },
      editCoupon:{
        id: "",
        name: "",
        code: "",
        from_date: "",
        from_time: "",
        to_date: "",
        to_time: "",
        worth: "",
        minPurchase: "",
        userId: "",
        type: "",
        usage: "",
        status: ""
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      successMessage: "",
      errorMessage: "",
      errorCodeMessage: "",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.couponData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.couponData.length;

    Master.couponList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.couponData = res.data.data;
    })
    Master.activeUsersList().then((res) => {
      console.log(res)
      let data = res.data.data;
      this.userData = data.map(item => ({ name: `${item.firstName} ${item.lastName} (${item.email})`
        , id: item.userId }));
      console.log(this.userData);
    })



  },
  validations: {
    coupon: {
      name: { required, minLength: minLength(3) },
      code: { required },
      from_date: { required },
      from_time: { required },
      to_date: { required },
      to_time: { required },
      worth: { required, numeric},
      minPurchase: { required, numeric},
      type: { required },
      usage: { required },
      status: { required }
    },
    editCoupon:{
      name: { required, minLength: minLength(3) },
      code: { required },
      from_date: { required },
      from_time: { required },
      to_date: { required },
      to_time: { required },
      worth: { required, numeric},
      minPurchase: { required, numeric},
      type: { required },
      usage: { required },
      status: { required }
    }
  },
  methods: {
    /**
     * Edit Coupon Modal
     */
    checkAll: function(){
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.couponData) {
          this.checkbox.push(this.couponData[key].id);
        }
      }
    },
    dynamicAmountType(data){
      if (data == 2 && this.editCoupon.worth >= 99){
        this.editCoupon.worth = "";
      }
    },
    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.couponStatusActive({
          ids: this.checkbox,
          status: 1
        }).then((res) => {
          Vue.swal({
            position: "center",
            icon: "success",
            title: "" + res.data.message + "",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.couponList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.couponData = res.data.data;
          })
        })
      }
    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.couponStatusInactive({
          ids: this.checkbox,
          status: 0
        }).then((res) => {
          Vue.swal({
            position: "center",
            icon: "success",
            title: "" + res.data.message + "",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.couponList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.couponData = res.data.data;
          })
        })
      }
    },
    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.couponBulkDelete({
            ids: this.checkbox,
            trash: 0
          }).then((res) => {
            Vue.swal({
              position: "center",
              icon: "success",
              title: "<p style='color: red'>" + res.data.message + "</p>",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.couponList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.couponData = res.data.data;
            })
          })
        }
      }
    },

    editModal(data){
      this.editshowmodal = true
      Master.couponDetails({
        id:data
      }).then((res) => {

        this.editCoupon.id = res.data.data.id
        this.editCoupon.name = res.data.data.name
        this.editCoupon.code = res.data.data.code
        this.editCoupon.worth = res.data.data.worth
        this.editCoupon.type = res.data.data.couponType
        this.editCoupon.status = res.data.data.status
        this.editCoupon.minPurchase = res.data.data.minPurchase
        this.editCoupon.usage = res.data.data.couponUsage
        if (res.data.data.firstName != null){
          this.editCoupon.userId = {id: res.data.data.userId, name:res.data.data.firstName+" "+res.data.data.lastName}
        }else if (res.data.data.userId == 0){
          this.editCoupon.userId = ""
        }

        var from_date = res.data.data.validFrom.substr(0, 10)
        var to_date = res.data.data.validTo.substr(0, 10)
        var from_time = res.data.data.validFrom.substr(10)
        var to_time = res.data.data.validTo.substr(10)

        this.editCoupon.from_date = from_date
        this.editCoupon.to_date = to_date
        this.editCoupon.from_time = moment(from_time.trim(" "), "hh:mm:ss").format("hh:mm:ss a")
        this.editCoupon.to_time = moment(to_time.trim(" "),"hh:mm:ss").format("hh:mm:ss a")


      })
    },

    async deleteCoupon(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.couponDelete(
              {
                trash: 0,
                id: data
                // trash: 0,
                // id: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.couponList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.couponData = res.data.data;
            })
          })
        }
      });
    },

    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.coupon.$invalid) {
        return;
      } else {
        // if (this.coupon.type == 1){
        //   this.coupon.worthTypeAmount = this.coupon.worth
        // }else if (this.coupon.type == 2){
        //   this.coupon.worthTypeAmount = this.coupon.percentage
        // }
        Master.addCoupon(
            {
              name : this.coupon.name,
              code : this.coupon.code,
              couponType : this.coupon.type,
              couponUsage : this.coupon.usage,
              worth : this.coupon.worth,
              validFrom : moment(new Date(this.coupon.from_date)).format("Y-MM-DD")+" "+moment(this.coupon.from_time, ["h:mm:ss A"]).format("HH:mm:ss"),
              validTo : moment(new Date(this.coupon.to_date)).format("Y-MM-DD")+" "+moment(this.coupon.to_time, ["h:mm:ss A"]).format("HH:mm:ss"),
              minPurchase : this.coupon.minPurchase,
              userId : this.coupon.userId.id?this.coupon.userId.id:0,
              status : this.coupon.status
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.couponList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.couponData = res.data.data;
          })
          this.showmodal = false;
          this.submitted = false;
          this.coupon.status = 1;
          this.coupon.name = "";
          this.coupon.code = "";
          this.coupon.type = "";
          this.coupon.worth = "";
          this.coupon.from_date = "";
          this.coupon.from_time = "";
          this.coupon.to_date = "";
          this.coupon.to_time = "";
          this.coupon.minPurchase = "";
          this.coupon.userId = "";
          this.coupon.usage= "";
        }).catch((err) => {
          this.$bvToast.toast(""+err.response.data.error.validFrom[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodal = true;
        })

      }

    },
    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editCoupon.$invalid) {
        return;
      } else {
        Master.couponUpdate(
            {
              id: this.editCoupon.id,
              name : this.editCoupon.name,
              code : this.editCoupon.code,
              couponType : this.editCoupon.type,
              worth : this.editCoupon.worth,
              validFrom : moment(new Date(this.editCoupon.from_date)).format("Y-MM-DD")+" "+moment(this.editCoupon.from_time, ["h:mm:ss A"]).format("HH:mm:ss"),
              validTo : moment(new Date(this.editCoupon.to_date)).format("Y-MM-DD")+" "+moment(this.editCoupon.to_time, ["h:mm:ss A"]).format("HH:mm:ss"),
              status : this.editCoupon.status,
              minPurchase : this.editCoupon.minPurchase,
              couponUsage : this.editCoupon.usage,
              userId : this.editCoupon.userId?this.editCoupon.userId.id:"",
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.couponList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.couponData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)

          // this.$bvToast.toast(""+err.response.data.errors+"", {
          //   title: `Error Message`,
          //   variant: 'danger',
          //   solid: true
          // });

          this.$bvToast.toast(""+err.response.data.error.validFrom[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });

          this.editshowmodal = true;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.coupon.status = 1;
      this.coupon.name = "";
      this.coupon.code = "";
      this.coupon.type = "";
      this.coupon.worth = "";
      this.coupon.from_date = "";
      this.coupon.from_time = "";
      this.coupon.to_date = "";
      this.coupon.to_time = "";
      this.coupon.minPurchase = "";
      this.coupon.usage= "";
      this.coupon.userId = "";
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" >
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> ADD
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-success mb-2 mr-1" @click="bulkActive">
                <i class="mdi mdi-check mr-2"></i> ACTIVE
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="bulkInactive">
                <i class="mdi mdi-cancel mr-2"></i> IN-ACTIVE
              </a>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> DELETE
              </a>
            </div>

            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorCodeMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorCodeMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      SHOW&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;ENTRIES
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      SEARCH:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="couponData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    sort-icon-left
                >
                  <template v-slot:cell(couponType)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-info"

                    >Direct Discount</div>
                    <div v-if="row.value == 2"
                         class="badge font-size-12 badge-soft-warning"
                    >Percentage</div>
                  </template>

                  <template v-slot:cell(name)="row">
                    <p class="badge font-size-12">
                      {{row.item.name}}
                    </p>
                  </template>


                  <template v-slot:cell(code)="row">
                    <p class="badge font-size-12">
                      {{row.item.code}}
                    </p>
                  </template>

                  <template v-slot:cell(couponUsage)="row">
                    <p class="badge font-size-12" v-if="row.item.couponUsage == 1">
                      SINGLE
                    </p>
                    <p class="badge font-size-12" v-else>
                      MULTIPLE
                    </p>
                  </template>

                  <template v-slot:cell(worth)="row">
                    <p class="badge font-size-12" v-if="row.item.couponType == 1">
                      QAR {{row.item.worth}}
                    </p>
                    <p class="badge font-size-12" v-else-if="row.item.couponType == 2">
                       {{row.item.worth}} %
                    </p>
                  </template>

                  <template v-slot:cell(minPurchase)="row">
                    <p class="badge font-size-12">
                      {{row.item.minPurchase}}
                    </p>
                  </template>

                  <template v-slot:cell(validFrom)="row">
                    <p class="badge font-size-12">
                      {{row.item.validFrom}} - {{row.item.validTo}}
                    </p>
                  </template>
                  <template v-slot:cell(firstName)="row">
                    <p class="badge font-size-12" style="text-transform: uppercase !important;">
                      {{row.item.firstName}} {{row.item.lastName}}
                    </p>
                  </template>







                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"

                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteCoupon(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD COUPON"
        title-class="text-dark font-18"
        hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">COUPON NAME</label>
              <input
                      id="name"
                      v-model="coupon.name"
                      type="text"
                      class="form-control"
                      placeholder="ENTER COUPON NAME"
                      :class="{ 'is-invalid': submitted && $v.coupon.name.$error }"
              />
              <div v-if="submitted && $v.coupon.name.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.name.required">COUPON NAME IS REQUIRED</span>
                <span v-if="!$v.coupon.name.minLength">COUPON NAME SHOULD BE ATLEAST 3 CHARACTER </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">COUPON CODE</label>
              <input
                      id="code"
                      v-model="coupon.code"
                      type="text"
                      class="form-control"
                      placeholder="ENTER COUPON CODE"
                      :class="{ 'is-invalid': submitted && $v.coupon.code.$error }"
              />
              <div v-if="submitted && $v.coupon.code.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.code.required">COUPON CODE IS REQUIRED</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>VALID FROM</label>
          <br />
          <div class="row">

            <div class="col-md-6">
              <date-picker
                  v-model="coupon.from_date"
                  :first-day-of-week="1"
                  lang="en"
                  :class="{ 'is-invalid': submitted && $v.coupon.from_date.$error }"
              >
              </date-picker>
              <div v-if="submitted && $v.coupon.from_date.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.from_date.required">FROM DATE IS REQUIRED</span>
              </div>
            </div>
            <div class="col-md-6">
              <date-picker
                  v-model="coupon.from_time"
                  format="hh:mm:ss a"
                  value-type="format"
                  type="time"
                  placeholder="hh:mm:ss a"
                  :class="{ 'is-invalid': submitted && $v.coupon.from_time.$error }"
              ></date-picker>
              <div v-if="submitted && $v.coupon.from_time.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.from_time.required">FROM TIME IS REQUIRED</span>
              </div>

            </div>
          </div>
        </div>

        <div class="form-group">
          <label>VALID TO</label>
          <br />
          <div class="row">
            <div class="col-md-6">
              <date-picker
                  v-model="coupon.to_date"
                  :first-day-of-week="1"
                  lang="en"
                  :class="{ 'is-invalid': submitted && $v.coupon.to_date.$error }"></date-picker>
              <div v-if="submitted && $v.coupon.to_date.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.to_date.required">TO DATE IS REQUIRED</span>
              </div>
            </div>
            <div class="col-md-6">
              <date-picker
                  v-model="coupon.to_time"
                  format="hh:mm:ss a"
                  value-type="format"
                  type="time"
                  placeholder="hh:mm:ss a"
                  :class="{ 'is-invalid': submitted && $v.coupon.to_time.$error }"
              ></date-picker>
              <div v-if="submitted && $v.coupon.to_time.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.to_time.required">TO TIME IS REQUIRED</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="type">COUPON TYPE</label>
              <select class="form-control"
                      id="type"
                      v-model="coupon.type"
                      :class="{ 'is-invalid': submitted && $v.coupon.type.$error }">
                <option value="" >COUPON TYPE</option>
                <option value="1">DIRECT DISCOUNT</option>
                <option value="2">PERCENTAGE</option>
              </select>
              <div
                      v-if="submitted && !$v.coupon.type.required"
                      class="invalid-feedback"
              >COUPON TYPE IS REQUIRED</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group"  v-if="coupon.type == 1 || coupon.type == ''">
              <label for="worth-add">WORTH AMOUNT</label>
              <input
                      id="worth-add"
                      v-model="coupon.worth"
                      type="text"
                      class="form-control"
                      placeholder="ENTER WORTH AMOUNT"
                      :class="{ 'is-invalid': submitted && $v.coupon.worth.$error }"
              />
              <div v-if="submitted && $v.coupon.worth.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.worth.required">WORTH AMOUNT IS REQUIRED</span>
                <span v-if="!$v.coupon.worth.numeric">WORTH AMOUNT SHOULD BE IN NUMERIC</span>
              </div>
            </div>

            <div class="form-group"  v-else-if="coupon.type == 2">
              <label for="percentage">PERCENTAGE (%)</label>
              <input
                      id="percentage"
                      v-model="coupon.worth"
                      type="text"
                      class="form-control"
                      placeholder="ENTER IN PERCENTAGE"
                      :class="{ 'is-invalid': submitted && $v.coupon.worth.$error }"
                      maxlength="2"

              />
              <div v-if="submitted && $v.coupon.worth.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.worth.required">PERCENTAGE IS REQUIRED</span>
                <span v-if="!$v.coupon.worth.numeric">PERCENTAGE SHOULD BE IN NUMERIC</span>
              </div>
            </div>


          </div>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="minPrice">MINIMUM PURCHASE</label>
              <input
                      id="minPrice"
                      v-model="coupon.minPurchase"
                      type="text"
                      class="form-control"
                      placeholder="ENTER MINIMUM PURCHASE"
                      :class="{ 'is-invalid': submitted && $v.coupon.minPurchase.$error }"
              />
              <div v-if="submitted && $v.coupon.minPurchase.$error" class="invalid-feedback">
                <span v-if="!$v.coupon.minPurchase.required">MINIMUM PURCHASE IS REQUIRED</span>
                <span v-if="!$v.coupon.minPurchase.numeric">MINIMUM PURCHASE SHOULD BE IN NUMERIC</span>
              </div>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="position">STATUS</label>
              <select class="form-control"
                      id="position"
                      v-model="coupon.status"
                      :class="{ 'is-invalid': submitted && $v.coupon.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                      v-if="submitted && !$v.coupon.status.required"
                      class="invalid-feedback"
              >Status is required</div>
            </div>




          </div>
        </div>
        <div class="form-group">
          <label for="type">COUPON USAGE</label>
          <select class="form-control"
                  id="type"
                  v-model="coupon.usage"
                  :class="{ 'is-invalid': submitted && $v.coupon.usage.$error }">
            <option value="" >COUPON USAGE</option>
            <option value="1">SINGLE</option>
            <option value="2">MULTIPLE</option>
          </select>
          <div
                  v-if="submitted && !$v.coupon.usage.required"
                  class="invalid-feedback"
          >COUPON USAGE IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="userId">USERS</label>
          <!--              <select class="form-control"-->
          <!--                      id="userId"-->
          <!--                      v-model="coupon.userId" style="text-transform: uppercase !important;">-->
          <!--                <option value="">SELECT USERS</option>-->
          <!--                <option v-for="user in userData" :value="user.userId"  :key="user.userId">{{user.firstName}} {{user.lastName}}</option>-->
          <!--              </select>-->

          <multiselect v-model="coupon.userId"
                       :options="userData"
                       track-by="name" label="name" value="id"
                       style="text-transform: uppercase !important;"
                       placeholder="SEARCH USERS"
          >

          </multiselect>
        </div>




        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="Edit Coupon"
        title-class="text-dark font-18"
        hide-footer
    >
      <form @submit.prevent="handleUpdate">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_name">COUPON NAME</label>
              <input
                      id="edit_name"
                      v-model="editCoupon.name"
                      type="text"
                      class="form-control"
                      placeholder="ENTER COUPON NAME"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.name.$error }"
              />
              <div v-if="editSubmitted && $v.editCoupon.name.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.name.required">COUPON NAME IS REQUIRED</span>
                <span v-if="!$v.editCoupon.name.minLength">COUPON NAME SHOULD BE ATLEAST 3 CHARACTER </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_code">COUPON CODE</label>
              <input
                      id="edit_code"
                      v-model="editCoupon.code"
                      type="text"
                      class="form-control"
                      placeholder="ENTER COUPON CODE"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.code.$error }"
              />
              <div v-if="editSubmitted && $v.editCoupon.code.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.code.required">COUPON CODE IS REQUIRED</span>
              </div>
            </div>
          </div>
        </div>





        <div class="form-group">
          <label>VALID FROM</label>
          <br />
          <div class="row">

            <div class="col-md-6">
              <date-picker
                  v-model="editCoupon.from_date"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                   :class="{ 'is-invalid': editSubmitted && $v.editCoupon.from_date.$error }"></date-picker>
              <div v-if="editSubmitted && $v.editCoupon.from_date.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.from_date.required">FROM DATE IS REQUIRED</span>
              </div>
            </div>
            <div class="col-md-6">
              <date-picker
                  v-model="editCoupon.from_time"
                  format="hh:mm:ss a"
                  value-type="format"
                  type="time"
                  placeholder="hh:mm:ss a"
                  :class="{ 'is-invalid': editSubmitted && $v.editCoupon.from_time.$error }"
              ></date-picker>
              <div v-if="editSubmitted && $v.editCoupon.from_time.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.from_time.required">FROM TIME IS REQUIRED</span>
              </div>

            </div>
          </div>
        </div>

        <div class="form-group">
          <label>VALID TO</label>
          <br />
          <div class="row">
            <div class="col-md-6">
              <date-picker
                  v-model="editCoupon.to_date"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                  :class="{ 'is-invalid': editSubmitted && $v.editCoupon.to_date.$error }"></date-picker>
              <div v-if="editSubmitted && $v.editCoupon.to_date.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.to_date.required">TO DATE IS REQUIRED</span>
              </div>
            </div>
            <div class="col-md-6">
              <date-picker
                  v-model="editCoupon.to_time"
                  format="hh:mm:ss a"
                  value-type="format"
                  type="time"
                  placeholder="hh:mm:ss a"
                  :class="{ 'is-invalid': editSubmitted && $v.editCoupon.to_time.$error }"
              ></date-picker>
              <div v-if="editSubmitted && $v.editCoupon.to_time.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.to_time.required">TO TIME IS REQUIRED</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_type">COUPONS TYPE </label>
              <select class="form-control"
                      id="edit_type"
                      v-model="editCoupon.type"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.type.$error }" @change="dynamicAmountType(editCoupon.type)">
                <option value="">COUPONS TYPE</option>
                <option value="1">DIRECT DISCOUNT</option>
                <option value="2">PERCENTAGE </option>
              </select>
              <div
                      v-if="editSubmitted && !$v.editCoupon.type.required"
                      class="invalid-feedback"
              >COUPONS TYPE IS REQUIRED</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group"  v-if="editCoupon.type == 1">
              <label for="edit_worth">WORTH AMOUNT</label>
              <input
                      id="edit_worth"
                      v-model="editCoupon.worth"
                      type="text"
                      class="form-control"
                      placeholder="ENTER WORTH AMOUNT"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.worth.$error }"
              />
              <div v-if="editSubmitted && $v.editCoupon.worth.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.worth.required">WORTH AMOUNT IS REQUIRED</span>
                <span v-if="!$v.editCoupon.worth.numeric">WORTH AMOUNT SHOULD BE IN NUMERIC </span>
              </div>
            </div>
            <div class="form-group"  v-else-if="editCoupon.type == 2">
              <label for="percentage">PERCENTAGE (%)</label>
              <input
                      id="percentage"
                      v-model="editCoupon.worth"
                      type="text"
                      class="form-control"
                      placeholder="ENTER IN PERCENTAGE"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.worth.$error }"
                      maxlength="2"

              />
              <div v-if="editSubmitted && $v.editCoupon.worth.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.worth.required">PERCENTAGE IS REQUIRED</span>
                <span v-if="!$v.editCoupon.worth.numeric">PERCENTAGE SHOULD BE IN NUMERIC</span>
              </div>
            </div>
          </div>



        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="worth">MINIMUM PURCHASE</label>
              <input
                      id="worth"
                      v-model="editCoupon.minPurchase"
                      type="text"
                      class="form-control"
                      placeholder="ENTER MINIMUM PURCHASE"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.minPurchase.$error }"
              />
              <div v-if="editSubmitted && $v.editCoupon.minPurchase.$error" class="invalid-feedback">
                <span v-if="!$v.editCoupon.minPurchase.required">MINIMUM PURCHASE IS REQUIRED</span>
                <span v-if="!$v.editCoupon.minPurchase.numeric">MINIMUM PURCHASE SHOULD BE IN NUMERIC</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_status">STATUS</label>
              <select class="form-control"
                      id="edit_status"
                      v-model="editCoupon.status"
                      :class="{ 'is-invalid': editSubmitted && $v.editCoupon.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                      v-if="editSubmitted && !$v.editCoupon.status.required"
                      class="invalid-feedback"
              >STATUS IS REQUIRED</div>
            </div>



          </div>
        </div>
        <div class="form-group">
          <label for="type">COUPON USAGE</label>
          <select class="form-control"
                  id="type"
                  v-model="editCoupon.usage"
                  :class="{ 'is-invalid': editSubmitted && $v.editCoupon.usage.$error }">
            <option value="" >COUPON USAGE</option>
            <option value="1">SINGLE</option>
            <option value="2">MULTIPLE</option>
          </select>
          <div
                  v-if="editSubmitted && !$v.editCoupon.usage.required"
                  class="invalid-feedback"
          >COUPON USAGE IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="userId">USERS</label>
          <!--              <select class="form-control"-->
          <!--                      id="userId"-->
          <!--                      v-model="editCoupon.userId" style="text-transform: uppercase !important;">-->
          <!--                <option value="">SELECT USERS</option>-->
          <!--                <option v-for="user in userData" :value="user.userId"  :key="user.userId">{{user.firstName}} {{user.lastName}}</option>-->
          <!--              </select>-->

          <multiselect v-model="editCoupon.userId" id="userId"
                       :options="userData"
                       track-by="name" label="name" value="id"
                       style="text-transform: uppercase !important;"
                       placeholder="SEARCH USERS"
          >

          </multiselect>
        </div>











        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>